import { createResource } from "redux-rest-resource";
import Config from "config";
import queryBuilder from "../../utilities/queryBuilder";
import PropertyReportState from "./types";
import { CasaiamRoles } from "utilities/type-helpers/userRoles";
import { getCurrentRole } from "utilities/featureFlags/roleAndPlanHelpers";

interface ResourceTypes {
  types: any;
  actions: any;
  rootReducer: (
    state?: PropertyReportState,
    actions?: any
  ) => PropertyReportState;
}

const resource: ResourceTypes = createResource({
  name: "propertyReport",
  pluralName: "propertyReports",
  url: `${Config.apiUrl}/${Config.customerKey}/property-report/:id`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  actions: {
    update: {
      assignResponse: false,
    },
    hydrate: {
      isPure: true,
      reduce: (state: any, action: any) => {
        if (state.items) {
          const newState = Object.assign({}, state);
          const items = state.items.map((item: any) => {
            if (item.id === action.context.id) {
              return action.context;
            }
            return item;
          });
          newState.items = items;
          newState.lastUpdated = Date.now();
          return newState;
        }
        return state;
      },
    },
    commitQueryFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        lastQuery: action.context,
      }),
    },
    commitListMetadataFor: {
      isPure: true,
      reduce: (state: any, action: any) => ({
        ...state,
        listMetadata: action.context,
      }),
    },
    fetch: {
      transformResponse: (res: any) => {
        if (res.body._embedded && res.body._embedded.property_report) {
          return { res, body: res.body._embedded.property_report };
        }
        return { res, body: [] };
      },
      query: (getState: any, { contextOpts }: any) => {
        const state = getState();
        const role = getCurrentRole(state);
        if (!contextOpts) {
          return {};
        }
        const cnxtOpts = Object.assign(
          {},
          {
            pageSize: 20,
            filter: [],
            orderBy: [],
          },
          contextOpts
        );
        const opts = Object.assign(
          {},
          {
            pageSize: 50,
            orderBy: [
              {
                type: "field",
                field: "created",
                direction: "desc",
              },
            ],
            filter: [],
          },
          {
            pageSize: cnxtOpts.pageSize,
            orderBy: [...cnxtOpts.orderBy],
            filter: [...cnxtOpts.filter],
          }
        );
        if (role === CasaiamRoles.ROLE_CASAONE_REPORTER) {
          const username =
            state.auth &&
            state.auth.profile &&
            state.auth.profile.data &&
            state.auth.profile.data.casaoneUser &&
            state.auth.profile.data.casaoneUser.username;
          opts.filter.push({
            type: "innerjoin",
            field: "viewers",
            alias: "viewers",
          });
          opts.filter.push({
            type: "eq",
            alias: "viewers",
            field: "user",
            where: "and",
            value: username || "there-is-no-user",
          });
        }
        const query = queryBuilder.build(opts.orderBy, opts.filter, {
          pagesize: opts.pageSize,
          page: contextOpts.page,
          context: "property-report-list",
        });
        return query;
      },
    },
  },
});

const types = resource.types;
const actions = resource.actions;
const rootReducer = resource.rootReducer;

export { types, actions, rootReducer };
