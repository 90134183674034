import { RootState } from "redux/store";
import { getLanguageLabel, getLanguageShortLabel } from "utilities/languages";
import { getPublisherItem, isPublisher } from "utilities/publishers";
import {
  BASE_DATA_PROJECT,
  BASE_DATA_PROPERTY,
  BASE_DATA_UNIT,
} from "../../utilities/propertyTypes";
import {
  getCurrentPlan,
  getCurrentRole,
} from "../../utilities/featureFlags/roleAndPlanHelpers";

export * from "./selectors/filterSelectors";

export const DEFAULT_USER_LANG = "de";

/** Get the current role */
export const selectRole = (state: RootState) => getCurrentRole(state);

/** Get the current plan */
export const selectPlan = (state: RootState) => getCurrentPlan(state);

/** Return the currently logged in user's username */
export function selectProfileUsername(state: RootState) {
  return state.auth.profile.data.casaoneUser?.username;
}

/** Return the whole profile object */
export function selectProfile(state: RootState) {
  return state.auth.profile;
}

/** Return the user's avatar */
export function selectProfileAvatar(state: RootState) {
  return state.auth.profile.data.casaoneUser?.contact?._embedded?.avatar;
}

/** Return the current user contact language */
export function selectContactLang(state: RootState) {
  const profile = state.auth.profile;
  const contactLang = profile.data.casaoneUser?.contact?.lang;

  if (contactLang) {
    return contactLang;
  }
  return DEFAULT_USER_LANG;
}

/** Return available publisher */
export function availablePublisher(state: RootState) {
  const contractOptions =
    state.auth.profile.data?.company?.contracts?.[0]?.options?.publishers || [];
  const availablePublications = contractOptions.map((publisher) => {
    const publisherItem = grabPublisherItem(publisher?.slug);

    const company = state.auth.profile.data?.company;

    let url: string | null = null;
    if (company) {
      if (publisherItem.slug === "icasa") {
        url = `https://icasa.ch/firma-angebote/${company.id}`;
      }
      if (publisherItem.slug === "luxuryestate") {
        url = `https://luxuryestate.ch/de/company/${company.id}`;
      }
      if (publisherItem.slug === "laendleimmo") {
        url = `https://www.laendleimmo.at/firmenverzeichnis/${company.id}`;
      }
      if (publisherItem.slug === "zimmersuche") {
        url = `https://zimmersuche.ch/de/preview-casasoft?id=${company.id}`;
      }
      if (publisherItem.slug === "schnaeppchenhaeuser") {
        url = `https://schnaeppchenhaeuser.ch/?company_slug=${company.id}`;
      }
    }
    if (publisher?.override_link) {
      url = publisher.override_link;
    }

    const publisherItemMaxImages =
      "max_images" in publisherItem ? publisherItem.max_images : undefined;
    const publisherItemMaxDocuments =
      "max_documents" in publisherItem
        ? publisherItem.max_documents
        : undefined;

    return {
      ...publisherItem,
      isProjectPublisher: publisher?.is_project_publisher ? true : false,
      name: publisher?.override_title
        ? publisher.override_title
        : publisherItem.name,
      url: url,
      max_images: publisher?.max_images
        ? publisher.max_images
        : publisherItemMaxImages,
      max_documents: publisher?.max_documents
        ? publisher.max_documents
        : publisherItemMaxDocuments,
    };
  });

  return availablePublications;
}

/** Return the company languages */
export function companyLanguages(state: RootState) {
  if (
    state.auth.profile?.data?.company?.contracts?.[0]?.options?.languages
      ?.length
  ) {
    const langs: string[] =
      state.auth.profile.data.company.contracts[0].options.languages;
    return langs.map((alang) => ({
      value: alang,
      label: getLanguageLabel(alang),
      shortLabel: getLanguageShortLabel(alang),
    }));
  }
}

/** Check whether the current company is "casasoft" (crm) */
export function selectIsCrm(state: RootState) {
  return (
    state.auth.profile?.data?.company?.id === "casasoft" &&
    state.auth.profile.data.apiCompanySection === "casasoft"
  );
}

/** Return the current logged in casaone user */
export function selectCasaoneUser(state: RootState) {
  return state.auth.profile.data.casaoneUser;
}

/** Return the current logged in casaone users contactId */
export function selectCasaoneUserContactId(state: RootState) {
  return state.auth.profile.data.casaoneUser?.contact?.id;
}

/** Return the current logged in casaone users contact */
export function selectCasaoneUserContact(state: RootState) {
  return state.auth.profile.data.casaoneUser?.contact;
}

/** Select company from auth store */
export function selectCompany(state: RootState) {
  return state.auth.profile.data.company;
}

/** Is Dev company */
export function isDevCompany(state: RootState) {
  return state.auth.profile.data.company?.id === "dev";
}

/** Select contract options from auth store */
export function selectContractOptions(state: RootState) {
  const company = selectCompany(state);
  return company?.contracts?.[0]?.options;
}

export function selectContractMainLang(state: RootState) {
  const contractOptions = selectContractOptions(state);
  if (contractOptions?.languages?.length) {
    return contractOptions.languages[0];
  }
  return DEFAULT_USER_LANG;
}

/** Wrapper around getPublisherItem to always return normalized data */
function grabPublisherItem(slug?: string) {
  if (isPublisher(slug)) {
    const pubdata = getPublisherItem(slug);
    return { ...pubdata, slug };
  }
  return {
    slug: slug || "",
    name: slug,
    logo: slug,
    disclaimer: slug,
    range: true,
    promotions: [],
  };
}

/** Get the available publishers filtered by whether they are project or single publishers */
export function selectPublishers(projectPublishers: "single" | "project") {
  return (state: RootState) => {
    const company = selectCompany(state);
    const contractOptions = selectContractOptions(state);

    const availablePublications = contractOptions?.publishers
      // filter for project or single publishers
      ?.filter((publisher) =>
        projectPublishers === "project"
          ? publisher?.is_project_publisher
          : !publisher?.is_project_publisher
      )
      // map to publisher item with data
      .map((publisher) => {
        const publisherItem = grabPublisherItem(publisher?.slug);

        let url: string | null = null;
        if (company) {
          if (publisherItem.slug === "icasa") {
            url = `https://icasa.ch/firma-angebote/${company.id}`;
          }
          if (publisherItem.slug === "luxuryestate") {
            url = `https://luxuryestate.ch/de/company/${company.id}`;
          }
          if (publisherItem.slug === "laendleimmo") {
            url = `https://www.laendleimmo.at/firmenverzeichnis/${company.id}`;
          }
          if (publisherItem.slug === "zimmersuche") {
            url = `https://zimmersuche.ch/de/preview-casasoft?id=${company.id}`;
          }
          if (publisherItem.slug === "schnaeppchenhaeuser") {
            url = `https://schnaeppchenhaeuser.ch/?company_slug=${company.id}`;
          }
        }
        if (publisher?.override_link) {
          url = publisher.override_link;
        }

        const publisherItemMaxImages =
          "max_images" in publisherItem ? publisherItem.max_images : undefined;
        const publisherItemMaxDocuments =
          "max_documents" in publisherItem
            ? publisherItem.max_documents
            : undefined;

        return {
          ...publisherItem,
          name: publisher?.override_title
            ? publisher.override_title
            : publisherItem.name,
          url: url,
          max_images: publisher?.max_images
            ? publisher.max_images
            : publisherItemMaxImages,
          max_documents: publisher?.max_documents
            ? publisher.max_documents
            : publisherItemMaxDocuments,
        };
      });

    return availablePublications;
  };
}

/** Return the current property by type */
export function selectPropertyByType(
  rootState: RootState,
  propertyType: string
) {
  let specificPropertyStore;

  switch (propertyType) {
    case BASE_DATA_UNIT:
      specificPropertyStore = rootState.units;
      break;
    case BASE_DATA_PROJECT:
      specificPropertyStore = rootState.projects;
      break;
    case BASE_DATA_PROPERTY:
      specificPropertyStore = rootState.properties;
      break;
    default:
      specificPropertyStore = undefined;
  }
  return specificPropertyStore?.item;
}
