import { NavLink } from "react-router-dom";
import EnvSettings from "components/miscellaneous/EnvSettings";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { PlanTypes } from "utilities/featureFlags/roleAndPlanHelpers";
import Config, { Env } from "config";

const getPlanLabel = (plan: PlanTypes): JSX.Element => {
  switch (plan) {
    case PlanTypes.Starter:
      return <span className="tw-text-lime-300">STARTER</span>;
    case PlanTypes.Basic:
      return <span className="tw-text-amber-400">BASIC</span>;
    case PlanTypes.Pro:
      return <span className="tw-text-rose-300">PRO</span>;
    case PlanTypes.ProPlus:
      return <span className="tw-text-teal-300">PRO+</span>;

    default:
      return <>UNKNOWN</>;
  }
};

const PlanAndRoleSettings = () => {
  const { currentPlan, currentRole, isPlanSimulated } = useFeatureFlags();

  return (
    <NavLink to="/dashboard-tech">
      <div className="tw-inline-block tw-align-top tw-ml-2 tw-text-sm tw-text-cs-shade-100 tw-font-bold">
        plan: {getPlanLabel(currentPlan)}{" "}
        {isPlanSimulated && (
          <span className="tw-text-cs-shade-300 tw-font-normal">
            [simulated]
          </span>
        )}
        <div className="tw-text-cs-shade-400">{currentRole}</div>
      </div>
    </NavLink>
  );
};

export const AppDevSettings = () => {
  const currentEnv = Config.env;
  const isLocalhost = process.env.NODE_ENV === "development";
  const isTestEnv = [Env.local, Env.test, Env.test1, Env.test2].includes(
    currentEnv
  );

  return (
    <>
      {isLocalhost && <EnvSettings />}
      {(isLocalhost || isTestEnv) && <PlanAndRoleSettings />}
    </>
  );
};
