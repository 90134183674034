import { Route } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { AppDevSettings } from "./AppDevSettings";

export const AppToolbarBrand = () => {
  return (
    <Route
      render={({ history }) => (
        <div
          role="button"
          tabIndex={-1}
          onClick={() => {
            history.push("/dash");
          }}
          className="tw-h-6 tw-relative tw-flex tw-items-center"
        >
          <Logo className="tw-w-full" />
          <AppDevSettings />
        </div>
      )}
    />
  );
};
