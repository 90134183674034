import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import resourceGrabber from "utilities/resourceGrabber";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Badge } from "@casasoft/styleguide/components/helpers-ux";
import Button from "@casasoft/styleguide/components/forms/Button";
import { Tooltip } from "@casasoft/styleguide/components/tooltip";
import { PlanTypes } from "utilities/featureFlags/roleAndPlanHelpers";

export interface TeaserNavItemProps {
  icon?: IconDefinition;
  label: string;
  planToAccess: PlanTypes.ProPlus | PlanTypes.Pro | PlanTypes.Basic;
  mainNav?: boolean;
  dataAttribute?: {
    [key: string]: string;
  };
}

export const TeaserItemContent = ({
  icon,
  label,
  planToAccess,
  mainNav,
}: TeaserNavItemProps) => {
  const { t } = useTranslation();

  let planToAccessRendered = "";
  switch (planToAccess) {
    case PlanTypes.Basic:
      planToAccessRendered = "Basic";
      break;
    case PlanTypes.Pro:
      planToAccessRendered = "Pro";
      break;
    case PlanTypes.ProPlus:
      planToAccessRendered = "Pro+";
      break;
    default:
      throw new Error("Invalid plan provided to teaser.");
  }

  return (
    <div className="tw-flex tw-items-center">
      {icon && (
        <FontAwesomeIcon
          className={`${mainNav ? "" : "tw-mr-1"}`}
          fixedWidth
          icon={icon}
          style={{ opacity: "0.5" }}
        />
      )}
      <span style={{ opacity: "0.5" }}>{label}</span>
      <Tooltip
        textAlign="left"
        placement="right"
        triggerAsChild
        content={
          <>
            <p>
              {t(`This feature is included in the {{plan}} version.`, {
                plan: planToAccessRendered,
              })}
            </p>
            <a
              href={
                resourceGrabber.grab("lang", "auth") === "fr"
                  ? "https://casaone.ch/fr/#prix"
                  : "https://casaone.ch/#preise"
              }
              target="_blank"
              style={{ color: "white", textDecoration: "underline" }}
              rel="noreferrer"
            >
              www.casaone.ch
            </a>
          </>
        }
      >
        <Badge marginLeft="tw-ml-2" color="primary" uppercase innerShadow>
          {planToAccessRendered}
        </Badge>
      </Tooltip>
    </div>
  );
};

const TeaserNavItem = ({
  icon,
  label,
  planToAccess,
  dataAttribute = {},
}: TeaserNavItemProps) => {
  return (
    <Button {...dataAttribute}>
      <TeaserItemContent
        icon={icon}
        label={label}
        planToAccess={planToAccess}
        mainNav
      />
    </Button>
  );
};

export default TeaserNavItem;
