import { selectPlan, selectRole } from "redux/auth/selectors";
import { useAppSelector } from "redux/hooks";
import { getMinimumRequiredPlan, hasAccessTo } from "utilities/featureFlags";
import {
  SIMULATED_PLAN_STORAGE_KEY,
  SIMULATED_ROLE_STORAGE_KEY,
} from "utilities/featureFlags/roleAndPlanHelpers";
import { isKCRole } from "utilities/type-helpers/userRoles";

export const useFeatureFlags = () => {
  const currentRole = useAppSelector(selectRole);
  const currentPlan = useAppSelector(selectPlan);

  const isCasasoftRole = isKCRole(currentRole);

  const isPlanSimulated =
    isKCRole(currentRole) && localStorage.getItem(SIMULATED_PLAN_STORAGE_KEY);
  const isRoleSimulated =
    isKCRole(currentRole) && localStorage.getItem(SIMULATED_ROLE_STORAGE_KEY);

  return {
    currentRole,
    isCasasoftRole,
    currentPlan,
    isPlanSimulated,
    isRoleSimulated,
    hasAccessTo,
    getMinimumRequiredPlan,
  };
};
