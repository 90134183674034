import { ReactNode, useEffect, useState } from "react";
import {
  Toolbar,
  ToolbarMenuSubItem,
} from "@casasoft/styleguide/components/toolbar";
import { Main, Notificationcenter } from "@casasoft/styleguide/components/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faPlug } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { UserRoles } from "utilities";
import AppNav from "./miscellaneous/Nav";
import DashboardQuicktasksCard from "./dash/dashboard/DashboardQuicktasksCard";
import DashboardQuickalertsCard from "./dash/dashboard/DashboardQuickalertsCard";
import DashboardQuickremindersCard from "./dash/dashboard/DashboardQuickremindersCard";
import DashboardCompleteYourProfileCard from "./dash/dashboard/DashboardCompleteYourProfileCard";
import Config from "config";
import { Helpcenter } from "../@casasoft/styleguide/components/helpcenter";
import useFaqArticleList from "hooks/useFaqArticleList";
import { useLocation } from "react-router";
import { axiosInstance } from "utilities/axios";
import { useAppSelector } from "redux/hooks";
import { selectCasaoneUser, selectContactLang } from "redux/auth/selectors";
import { getCasaoneMarketplaceIntegrationLink } from "./toolbar/helpers/getCasaoneMarketplaceIntegrationLink";
import { useToolbarItems } from "./toolbar/hooks/useToolbarItems";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { AppToolbarBrand } from "./app/AppToolbarBrand";

export type ToggleState = "open" | "close" | "peek";

interface AppProps {
  children: ReactNode;
}

function App({ children }: AppProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const lang = useAppSelector(selectContactLang);
  const { currentRole, hasAccessTo } = useFeatureFlags();

  const {
    faqArticles,
    activeItem,
    similarTopics,
    isLoadingFaqs,
    onFaqTopicSelect,
    onFaqArticleSelect,
  } = useFaqArticleList();

  const [faqToggleState, setFaqToggleState] = useState<ToggleState>("close");
  const [notificationCenterToggleState, setNotificationCenterToggleState] =
    useState<ToggleState>("close");

  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    // Get number of non downloaded invoices
    if (hasAccessTo("invoiceManagement")) {
      axiosInstance
        .get(
          `${Config.api2Url}/company/${Config.customerKey}/invoice?hasInvoiceFile=true&exists[downloadedAt]=false`
        )
        .then((response) => setInvoicesCount(response.data.total_items || 0));
    }
  }, [hasAccessTo]);

  useEffect(() => {
    if (pathname.includes("/faq/list")) {
      setFaqToggleState("close");
    }
    // reset topic select on path change (used useConditionalEffect to not trigger useEffect on function onFaqTopicSelect call from elsewhere)
    onFaqTopicSelect("");
  }, [pathname, onFaqTopicSelect]);

  const [invoicesCount, setInvoicesCount] = useState(0);

  const casaoneUser = useAppSelector(selectCasaoneUser);

  // Does not make sense to go ahead without a user
  if (!casaoneUser || !casaoneUser.roles?.length) {
    throw new Error("Either CasaoneUser or his roles are not found");
  }

  const casaoneMarketplaceIntegrations: ToolbarMenuSubItem = {
    key: "casaone-marketplace-integrations",
    action: { externalLink: getCasaoneMarketplaceIntegrationLink(lang) },
    label: t("Integration marketplace"),
    icon: <FontAwesomeIcon fixedWidth icon={faPlug} />,
    suffixIcon: (
      <span style={{ top: "-0.1rem", position: "relative" }}>
        <FontAwesomeIcon
          fixedWidth
          opacity={0.4}
          icon={faExternalLink}
          size="xs"
        />
      </span>
    ),
  };

  const {
    toolbarItems,
    invoiceItem,
    menuIcon,
    securitySettingsItem,
    logoutItem,
    settingsItem,
  } = useToolbarItems({
    invoicesCount,
    faqToggleState,
    setFaqToggleState,
    notificationCenterToggleState,
    setNotificationCenterToggleState,
  });

  return (
    <div className="tw-h-full tw-min-h-0 tw-relative tw-flex tw-flex-col">
      <Toolbar
        brand={<AppToolbarBrand />}
        toggleNav={(navToggle) => {
          setNavOpen(navToggle);
          setNotificationCenterToggleState("close");
        }}
        profileIcon={menuIcon}
        profileInfo={{
          // Contact is related to the user 1-1 but could be absent. Defaulting to empty string
          firstName: casaoneUser.contact?.firstName || "",
          lastName: casaoneUser.contact?.lastName || "",
          role: UserRoles.getRoleLabel(currentRole),
        }}
        closeMenu={
          notificationCenterToggleState === "open" || faqToggleState === "open"
        }
        desktopItems={[
          toolbarItems.faq,
          toolbarItems.calendar,
          toolbarItems.notifications,
          toolbarItems.settingsDropdown,
        ]}
        mobileItems={[
          settingsItem,
          securitySettingsItem,
          toolbarItems.calendar,
          toolbarItems.notifications,
          invoiceItem,
          toolbarItems.faq,
          casaoneMarketplaceIntegrations,
          logoutItem,
        ]}
      />
      <div role="presentation" className="tw-h-full tw-min-h-0 tw-relative">
        <Notificationcenter
          collapsed={notificationCenterToggleState !== "open"}
          onToggle={() => {
            setNotificationCenterToggleState(
              notificationCenterToggleState === "open" ? "close" : "open"
            );
          }}
          peek={notificationCenterToggleState === "peek"}
        >
          {notificationCenterToggleState === "open" && (
            <div className="tw-p-2 tw-h-full tw-overflow-y-scroll">
              {hasAccessTo("yourUserSettings") && (
                <>
                  <DashboardCompleteYourProfileCard size="small" />
                  <div className="tw-mb-4" />
                </>
              )}
              {hasAccessTo("eventManagement") && (
                <>
                  <DashboardQuickremindersCard compactView />
                  <div className="tw-mb-4" />
                  <DashboardQuicktasksCard compactView />
                  <div className="tw-mb-4" />
                </>
              )}
              {hasAccessTo("basicCasaoneFeatures") && (
                <DashboardQuickalertsCard />
              )}
            </div>
          )}
        </Notificationcenter>
        <Notificationcenter
          collapsed={faqToggleState !== "open"}
          onToggle={() => {
            setFaqToggleState(faqToggleState === "open" ? "close" : "open");
          }}
          peek={faqToggleState === "peek"}
        >
          {faqToggleState === "open" && (
            <Helpcenter
              isLoading={isLoadingFaqs}
              onTopicSelect={onFaqTopicSelect}
              onArticleSelect={onFaqArticleSelect}
              similiarTopics={similarTopics}
              faqArticles={faqArticles}
              activeArticle={activeItem}
              pathname={pathname}
              onClose={() => {
                setFaqToggleState("close");
              }}
            />
          )}
        </Notificationcenter>
        <Main
          onClick={() => {
            if (notificationCenterToggleState === "open") {
              setNotificationCenterToggleState("close");
            }
          }}
        >
          <AppNav
            collapsed={!navOpen}
            onToggle={() => {
              setNavOpen(navOpen ? false : true);
            }}
            navToggle={navOpen}
          />
          {children}
        </Main>
      </div>
    </div>
  );
}

export default App;
