import store, { type RootState } from "redux/store";
import {
  isKCRole,
  isRole,
  Role,
  Roles,
} from "utilities/type-helpers/userRoles";

// Local storage key for the simulated role
export const SIMULATED_ROLE_STORAGE_KEY = "simulatedRole";
export const SIMULATED_PLAN_STORAGE_KEY = "simulatedPlan";

// Ideally these should come from BE (casaone product entity -> options-schema), but for now, we are hardcoding them
export enum PlanTypes {
  Pro = "pro",
  ProPlus = "pro-plus",
  Basic = "basic",
  Starter = "starter",
}

export const getCurrentRole = (state?: RootState) => {
  const authStore = state?.auth || store.getState().auth;
  const currentRole = authStore.profile.data.casaoneUser?.roles?.[0];

  if (
    currentRole &&
    isRole(currentRole) &&
    Object.keys(Roles).includes(currentRole)
  ) {
    const currentRoleSimulated =
      isKCRole(currentRole) && localStorage.getItem(SIMULATED_ROLE_STORAGE_KEY);
    // Role Typeguard plus casting to Role type
    return (currentRoleSimulated || currentRole) as Role;
  } else throw new Error("Invalid role");
};

export const getCurrentPlan = (state?: RootState) => {
  const authStore = state?.auth || store.getState().auth;
  const currentRole = getCurrentRole(state);

  const currentPlanSimulated =
    isKCRole(currentRole) && localStorage.getItem(SIMULATED_PLAN_STORAGE_KEY);

  const currentPlan =
    currentPlanSimulated ||
    authStore.profile.data.company?.contracts?.[0]?.options?.plan;

  switch (currentPlan) {
    case "pro":
      return PlanTypes.Pro;
    case "pro-plus":
      return PlanTypes.ProPlus;
    case "basic":
      return PlanTypes.Basic;
    case "starter":
      return PlanTypes.Starter;
    default:
      throw new Error(`Unknown plan: ${currentPlan}`);
  }
};
